@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "JetBrainsRegular";
  src: url(./fonts/JetBrainsMono-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "JetBrainsBold";
  src: url(./fonts/JetBrainsMono-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "JetBrainsBolder";
  src: url(./fonts/JetBrainsMono-ExtraBold.ttf);
  font-weight: bolder;
}

.custom-scale-101 {
  --tw-scale-x: 1.01;
  --tw-scale-y: 1.01;
}

.font-jetbrains-regular {
  font-family: JetBrainsRegular, "Courier New", Courier, monospace;
}

.font-jetbrains-bold {
  font-family: JetBrainsBold, "Courier New", Courier, monospace;
}

.font-jetbrains-bolder {
  font-family: JetBrainsBolder, "Courier New", Courier, monospace;
}
